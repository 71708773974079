/* Heading Extra Large styling */
.componentXl {
  & > .subtitleLayout {
    margin-top: var(--size-8);
  }
}

.componentHeaderTitleXl {
  font-size: var(--font-size-40-82);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-lg);
}

.componentHeaderSubtitleXl {
  font-size: var(--font-size-32-40);
  line-height: var(--line-height-sm);
}

/* Heading Large styling */
.componentHeaderTitleLg {
  font-size: var(--font-size-40-52);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-lg);
}

.componentHeaderSubtitleLg {
  font-size: var(--font-size-28-32);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xl);
}

/* Heading Medium styling */
.componentMd {
  & > .subtitleLayout {
    margin-top: var(--size-8);
  }
}

.componentHeaderTitleMd {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-lg);
}

.componentHeaderSubtitleMd {
  font-size: var(--font-size-24-28);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xl);
}

/* Heading Small styling */
.componentSm {
  & > .subtitleLayout {
    margin-top: var(--size-8);
  }
}

.componentHeaderSubtitleSm {
  font-size: var(--font-size-16-18);
  line-height: var(--line-height-sm);
}

.componentHeaderTitleSm {
  font-size: var(--font-size-24-28);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-lg);
}

/* Base styling */
.componentBase {
  font-family: var(--font-family-heading);
  color: var(--color-blue-700);
}
