:root {
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
  --ease-out-quart: cubic-bezier(0.25, 1, 0.5, 1);
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --ease-in-out: cubic-bezier(0.37, 0, 0.63, 1);

  --duration-150: 150ms;
  --duration-200: 200ms;
  --duration-750: 750ms;
}
