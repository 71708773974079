.page {
  background-color: var(--color-blue-300);
  display: flex;
  align-items: center;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > .headingLayout {
    margin-bottom: var(--size-40);
  }
}
